import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../layouts"
import '../styles/news.scss';
import { Helmet } from "react-helmet"
import CustomMarkdown from "../utils/customMarkdown";
import 'uikit/dist/css/uikit.css'


// const CTAList = loadable(() => import("../components/cta-list/ctaList"));

import CTAList from '../components/cta-list/ctaList';


const News = () => {

  const data = useStaticQuery(graphql`
        {
          strapiNewsPage {
            SEODescription
            SEOTitle
            FooterDisclaimer
            Title
            Subtitle
          }
          allStrapiArticles(sort: {order: DESC, fields: created_at}) {
            nodes {
              Author
              Description
              Title
              Slug
              created_at
              Image{
                localFile{
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        gatsbyImageData(
                          width: 1900
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                }
              }
            }
          }
          allFile {
            edges {
              node {
                publicURL
                id
                name
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }

    `)

  const contentsList = [];

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    // console.log(imgUrl)
    return (imgUrl)
  }

  return (

    <Layout SEOTitle={data.strapiNewsPage.SEOTitle} SEODescription={data.strapiNewsPage.SEOTitle} footerDisclaimer={data.strapiNewsPage.FooterDisclaimer}>
      <Helmet>
        {data.strapiNewsPage?.MetaTags?.map((item, index) => {
          return (
            <>

              <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
            </>
          )
        })}
      </Helmet>

      <div className="news-page">
        <div className="news-page-container">
          <div className="news-page-content">
            <div className="news-page-header">
              {
                data.strapiNewsPage.Title ? <h2>{data.strapiNewsPage.Title}</h2> : ""
              }
              {
                data.strapiNewsPage.Subtitle ? <CustomMarkdown children={data.strapiNewsPage.Subtitle} /> : ""

              }
            </div>
            <div className="news-page-body">
              {
                  data.allStrapiArticles.nodes ? data.allStrapiArticles.nodes.map((item, index) => {

                    contentsList.push({
                      
                      Title:item.Title,
                      Description:item.Description,
                      Author:item.Author,
                      Slug:item.Slug,
                      Image:item.Image,
                      created_at: item.created_at,
                      CardType: 'news'
                    })
                  }) : ""
              }
              {
                contentsList.length ?
                  <CTAList
                    contents={contentsList}
                    cardType={'news'}
                  ></CTAList>
                  : ""

              }
            </div>
          </div>
        </div>
      </div>
    </Layout>

  )

}

export default News